import React from "react"
import ImageMeta from "./ImageMeta"
import PropTypes from "prop-types"

export default function ResponsiveImage(props) {
    return (
        <>
            <ImageMeta
                cloudName="nuvolum"
                publicId={props.desktopImageId}
                wrapperClassName="is-hidden-touch"
                width="auto"
                responsive
                useAR
            ></ImageMeta>
            <ImageMeta
                cloudName="nuvolum"
                publicId={props.mobileImageId}
                wrapperClassName="is-hidden-desktop"
                width="auto"
                responsive
                useAR
            // style={{ paddingTop: "68px" }}
            ></ImageMeta>
        </>
    )
}

ResponsiveImage.propTypes = {
    desktopImageId: PropTypes.string.isRequired,
    mobileImageId: PropTypes.string.isRequired
}